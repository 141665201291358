import { Controller } from "stimulus"
import * as Turbo from "@hotwired/turbo"

export default class extends Controller {
  static values = { next: String }

  connect() {
    this.element.addEventListener("turbo:submit-end", (event) => {
      this.next(event)
    })
  }

  next(event) {
    if (event.detail.fetchResponse.response.redirected) {
      Turbo.visit(event.detail.fetchResponse.response.url)
    }
  }
}