// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import "flagpack/dist/flagpack.css"
import "bootstrap/dist/css/bootstrap"
import "country-select-js/build/css/countrySelect"

import LocalTime from "local-time"
LocalTime.start()

require("jquery")
require("@popperjs/core")
import "bootstrap"
import { Tooltip, Popover } from 'bootstrap'
jQuery(function () {
  let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  let tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new Tooltip(tooltipTriggerEl)
  })

  let popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  let popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new Popover(popoverTriggerEl)
  })
})

import "country-select-js/build/css/countrySelect.min.css"
import "country-select-js/src/js/countrySelect.js"
import "loadingio-bar/dist/loading-bar.min.css"
import "loadingio-bar/dist/loading-bar.min.js"

import "@fortawesome/fontawesome-free/css/all"


Rails.start()
ActiveStorage.start()


import "controllers"