import { Controller } from "stimulus";
import { Modal } from "bootstrap";

export default class extends Controller {
  connect() {
    let ahrefsModal = new Modal(document.getElementById("ahrefs_modal"), {
      keyboard: false
    })
    ahrefsModal.show()
  }
}