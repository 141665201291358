import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["keyword", "domain", "country"]
  connect() {
    this.send_report();
  }

  send_report() {
    console.info(this.keywordTarget.value)
    document.getElementById("keyword").value = this.keywordTarget.value
    document.getElementById("domain").value = this.domainTarget.value
    document.getElementById("country").value = this.countryTarget.value
  }
}